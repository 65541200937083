import { Row, Col } from 'react-bootstrap';
import { ReactComponent as Logo } from '../images/wcth-logo.svg';

const Header: React.FC = () => {
  return (
    <Row>
      <Col xs={12} md={{ span: 8, offset: 2 }}>
        <Logo className="img-fluid logo" />
        <hr className="divider" />
        <h2>Welcome to early access to the Hearties Lens on the Hallmark Movie Checklist!</h2>
      </Col>
    </Row>
  );
};

export default Header;
