import { ReactComponent as CornerElement } from '../images/corner-element.svg';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MobileMessage from './MobileMessage';
import PrivacyPolicy from './PrivacyPolicy';
import usePageView from '../hooks/usePageView';

const DesktopInstructions: React.FC = () => {
  usePageView('Desktop Page', '/');
  return (
    <div className="body-content">
      <div className="container">
        <div className="corner-elements">
          <CornerElement className="top-left" />
          <CornerElement className="top-right" />
          <CornerElement className="bottom-left" />
          <CornerElement className="bottom-right" />
        </div>
        <Header />
        <MobileMessage />
        <Footer />
        <PrivacyPolicy />
      </div>
    </div>
  );
};
export default DesktopInstructions;
