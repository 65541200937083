import { Col, Row } from 'react-bootstrap';
import { ReactComponent as HallmarkLogo } from '../images/hallmark-logo.svg';

const Footer: React.FC = () => (
  <Row>
    <Col>
      <HallmarkLogo className="img-fluid hallmark-logo" />
    </Col>
  </Row>
);

export default Footer;
