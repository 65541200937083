import Axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { GoogleAnalyticsEvent } from 'windowConfig';
import usePageView from '../hooks/usePageView';

const invalidPasswordResponseCode = 401;
const tooManyRequestsResponseCode = 429;

interface IPasswordFormProps {
  setDeeplink: (deeplink: string) => void;
}

const defaultHeaders: AxiosRequestHeaders = {
  Pragma: 'no-cache',
  'Content-Type': 'application/json',
};

const request: AxiosRequestConfig = {
  method: 'POST',
  url: '/api/deeplink',
  headers: defaultHeaders,
};

type ErrorType = 'Unknown' | 'RateLimit' | 'InvalidPassword' | 'Required';

const getErrorCopy = (errorType: ErrorType) => {
  switch (errorType) {
    case 'Unknown':
      return 'Sorry, an unexpected error occurred. Please try again.';
    case 'InvalidPassword':
      return "The password that you've entered is incorrect.";
    case 'RateLimit':
      return 'Too many attempts. Please wait a minute and try again.';
    case 'Required':
      return 'Please enter a password.';
  }
};

const ErrorMessage: React.FC<{ errorType: ErrorType | null }> = ({ errorType }) => {
  if (!errorType) {
    return null;
  }
  return <span className="error-message">{getErrorCopy(errorType)}</span>;
};

const PasswordForm: React.FC<IPasswordFormProps> = ({ setDeeplink }) => {
  usePageView('Mobile Form Page', '/');

  const [password, setPassword] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);

  const validate = () => {
    return password.length > 0;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting) {
      setError(null);
      const isValid = validate();
      if (!isValid) {
        setError('Required');
      } else {
        GoogleAnalyticsEvent('event', 'FormSubmit');
        setIsSubmitting(true);
        Axios({ ...request, data: { password } })
          .then(({ data }: AxiosResponse<string>) => {
            GoogleAnalyticsEvent('event', 'FormSuccess');
            setDeeplink(data);
          })
          .catch((error: Error | AxiosError) => {
            if (
              Axios.isAxiosError(error) &&
              error.response &&
              (error.response.status === tooManyRequestsResponseCode ||
                error.response.status === invalidPasswordResponseCode)
            ) {
              setError(error.response.status === invalidPasswordResponseCode ? 'InvalidPassword' : 'RateLimit');
              GoogleAnalyticsEvent('event', `FormError - ${error.response.status}`);
            } else {
              setError('Unknown');
              GoogleAnalyticsEvent('event', 'FormError - Unknown Error');
            }
            setIsSubmitting(false);
          });
      }
    }
  };
  return (
    <>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <p>Enter your password to begin</p>
          <form onSubmit={onSubmit} noValidate>
            <div className={`form-input-container ${error ? 'error animateIn' : ''}`}>
              <input
                required
                name="password"
                type="password"
                maxLength={100}
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  if (!isSubmitting) {
                    setPassword(e.target.value);
                    if (error !== 'RateLimit') {
                      setError(null);
                    }
                  }
                }}
              />
              <ErrorMessage errorType={error} />
            </div>
            <button
              type="submit"
              className={'btn btn-primary w-100' + (isSubmitting ? ' submitting' : ' ')}
              disabled={isSubmitting}
            >
              <span className={'active' + (isSubmitting ? ' loading' : ' ')}>
                Submit<i className="arrow right"></i>
              </span>
              <div className="btn-icon"></div>
            </button>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default PasswordForm;
