/* eslint-disable react/jsx-no-target-blank */
import { Button, Col, Row } from 'react-bootstrap';
import { isAndroid, isIOS } from 'react-device-detect';
import { ReactComponent as AppStore } from '../images/btn-appStore.svg';
import { ReactComponent as PlayStore } from '../images/btn-playStore.svg';
import { GoogleAnalyticsEvent } from 'windowConfig';
import usePageView from '../hooks/usePageView';

interface IDeeplinkContainerProps {
  deeplink: string;
}

type DownloadToShow = 'Android' | 'iOS' | 'Both';

const getDownloadToShow: () => [DownloadToShow, number] = () => {
  // If both false or both true - show both
  if (isIOS === isAndroid) {
    return ['Both', 6];
  }
  return [isIOS ? 'iOS' : 'Android', 6];
};

const [downloadToShow, columnWidth] = getDownloadToShow();

const DeeplinkContainer: React.FC<IDeeplinkContainerProps> = ({ deeplink }) => {
  usePageView('Mobile deeplink page', '/deeplink');
  return (
    <>
      <Row>
        <Col xs={12}>
          <a href={deeplink}>
            <Button
              className="btn btn-primary w-100"
              onClick={() => {
                GoogleAnalyticsEvent('event', 'start the hearties lens click');
              }}
            >
              start the hearties lens<i className="arrow right"></i>
            </Button>
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="app-store-header">Don't have the app?</p>
        </Col>
      </Row>
      <Row className="row-tight button-container">
        {downloadToShow !== 'Android' && (
          <Col xs={columnWidth}>
            <a href="https://itunes.apple.com/us/app/hallmark-movie-checklist/id1439967089?ls=1&mt=8" target="_blank">
              <button
                type="submit"
                className="button-link"
                onClick={() => {
                  GoogleAnalyticsEvent('event', 'itunes app store click');
                }}
              >
                <AppStore className="img-fluid" />
              </button>
            </a>
          </Col>
        )}
        {downloadToShow !== 'iOS' && (
          <Col xs={columnWidth}>
            <a href="https://play.google.com/store/apps/details?id=com.hallmark.countdown" target="_blank">
              <button
                type="submit"
                className="button-link"
                onClick={() => {
                  GoogleAnalyticsEvent('event', 'google play store app click');
                }}
              >
                <PlayStore className="img-fluid" />
              </button>
            </a>
          </Col>
        )}
      </Row>
    </>
  );
};

export default DeeplinkContainer;
