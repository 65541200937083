import { Row, Col } from 'react-bootstrap';
import { ReactComponent as Divider } from '../images/icon-divider.svg';

const MobileMessage: React.FC = () => {
  return (
    <Row>
      <Col xs={12} md={{ span: 8, offset: 2 }}>
        <Divider className="section-divider" />
        <h2 className="desktop-warning">View this site on your mobile device to access the Hearties Lens.</h2>
      </Col>
    </Row>
  );
};

export default MobileMessage;
