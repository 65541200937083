import { useState } from 'react';
import './styles/style.scss';
import './styles/typography.scss';
import Header from './components/Header';
import Footer from 'components/Footer';
import PasswordForm from './components/PasswordForm';
import DeeplinkContainer from './components/DeeplinkContainer';
import { ReactComponent as CornerElement } from './images/corner-element.svg';
import { isMobileOnly } from 'react-device-detect';
import DesktopInstructions from './components/DesktopInstructions';
import PrivacyPolicy from 'components/PrivacyPolicy';

function App() {
  window.history.replaceState(null, '', '/');
  const [deeplink, setDeeplink] = useState<string | null>(null);

  if (!isMobileOnly) {
    return <DesktopInstructions />;
  }
  return (
    <>
      <div className="body-content">
        <div className="container">
          <div className="corner-elements">
            <CornerElement className="top-left" />
            <CornerElement className="top-right" />
            <CornerElement className="bottom-left" />
            <CornerElement className="bottom-right" />
          </div>
          <Header />
          {deeplink ? <DeeplinkContainer deeplink={deeplink} /> : <PasswordForm setDeeplink={setDeeplink} />}
          <Footer />
          <PrivacyPolicy />
        </div>
      </div>
    </>
  );
}
export default App;
