const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <ul>
        <li>
          <a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
        </li>
        <li>
          <a href="https://www.hallmarkchannel.com/ca-privacy-policy" target="_blank" rel="noreferrer">
            California Privacy Notice
          </a>
        </li>
        <li>
          <a href="https://www.hallmarkchannel.com/do-not-sell-my-info" target="_blank" rel="noreferrer">
            California Do Not Sell My Info
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
