import { DependencyList, useEffect } from 'react';
import { GoogleAnalyticsEvent } from '../windowConfig';

// TODO - could make pagePath optional
// const location = useLocation();
// use location.pathname when not defined

const usePageView = (pageTitle: string, pagePath: string, dependencyList: DependencyList = []) => {
  useEffect(() => {
    GoogleAnalyticsEvent('event', 'page_view', {
      page_title: pageTitle,
      page_path: pagePath,
    });
  }, dependencyList);
};

export default usePageView;
